<template>
	<v-sheet v-if="organisation">
		<v-row>
			<v-col>
				<h2 class="ig-header">{{ organisation.name }}</h2>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-text-field
					:label="$t('organisations.fields.address')"
					v-model="organisation.address.streetAddress"
				></v-text-field>
				<v-text-field
					:label="$t('organisations.fields.city')"
					v-model="organisation.address.city"
				></v-text-field>
				<v-text-field
					:label="$t('organisations.fields.state')"
					v-model="organisation.address.state"
				></v-text-field>
				<v-text-field
					:label="$t('organisations.fields.postcode')"
					v-model="organisation.address.postcode"
				></v-text-field>
			</v-col>
			<v-col class="">
				<v-text-field
					v-model="organisation.owner"
					:label="$t('organisations.fields.owner')"
				>
				</v-text-field>
				<v-select
					:items="['Privat', 'Freigemeinnützig', 'Öffentlich']"
					v-model="organisation.type"
					:label="$t('organisations.fields.type')"
				>
				</v-select>
				<v-switch
					v-model="organisation.universityHospital"
					:label="$t('organisations.fields.universityhospital')"
				>
				</v-switch>

				<v-text-field
					:value="organisation.beds"
					@input="v => validateNumber('beds', v)"
					type="number"
					min="0"
					:label="$t('organisations.fields.beds')"
				>
				</v-text-field>

				<v-text-field
					:value="organisation.ik_nummer"
					@input="v => validateNumber('ik_nummer', v)"
					type="number"
					min="0"
					:label="$t('organisations.fields.ik_nummer')"
				>
				</v-text-field>
			</v-col>
		</v-row>

		<p class="text-right">
			<v-btn @click="save" color="primary">{{ $t("buttons.save") }}</v-btn>
		</p>
		<saved v-model="saved"></saved>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Vuex from "vuex";
import Saved from "@c/notices/Saved";
export default {
	name: "OrganisationProfile",
	data: () => {
		return {
			saved: false,
		};
	},
	components: {
		Saved,
	},
	computed: {
		...Vuex.mapState({
			auth: (state) => state.auth,
			organisation: (state) => state.organisation.data,
			departments: (state) => state.departments.data,
		}),
		orgDepartments() {
			const self = this;
			if (!self.organisation.departments) {
				return [];
			} else {
				return self.organisation.departments.filter(
					(a) => self.departments[a]
				);
			}
		},
	},
	methods: {
		save() {
			var self = this;
			var data = {
				address: self.organisation.address,
				owner: self.organisation.owner,
				universityHospital: self.organisation.universityHospital,
				beds: self.organisation.beds,
				type: self.organisation.type,
				ik_nummer: self.organisation.ik_nummer
			};
			self.$store.dispatch("organisation/patch", data).then(() => {
				self.saved = true;
			});
		},
		validateNumber(k, v){
			if( v < 0 ){
				v = 0;
			}
			this.organisation[k] = v;
		}
	},
};
//
</script>
"
